import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';


import {GrayClock} from './../../assets/svgs'

const BlogDuration = ({data}) => {
  const breakpoints = useBreakpoint();

    const speed = 225; // 225 words per minutes
    const wordCount = data.match(/\w+/g).length;
    const time = Math.ceil(wordCount / speed);
  
  return(
    <div>
    {!breakpoints.xs && <><GrayClock /><span>-</span></>}<span>{time} min read</span>     
    </div>
  )
}
export default BlogDuration
