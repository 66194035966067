import * as React from "react"

import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


import Container from "./Container"

const PageLoader = () => {  
  return (
    <div>  
       
      <Container>
        <Loader
        type="Oval"
        color="#3c4fe0"
        height={150}
        width={150}        
      />
      </Container>       
    </div>
  )
}

export default PageLoader
