import * as React from "react"

import removeMd from 'remove-markdown'
import ReactMarkdown from 'react-markdown'

import BlogDate from "./BlogDate";
import BlogDuration from "./BlogDuration";

import classNames from "classnames"

import Container from "./../Container"




const BlogFull = ({data}) => {  
  const apiBase = process.env.REACT_IMAGE_BASE_URL;
  const banner = apiBase+data.banner.formats.large.url;
  const plainText = removeMd(data.content, 
      { 
          useImgAltText: false      // replace images with alt-text, if present (default: true)
      })
  
  return (
    <div className={classNames('')}>   
    <figure>
    <img src={banner} alt={data.title} />
    </figure>
    <Container className={''}>  
      
    <h1><strong>{data.title}</strong></h1>   
    <div>
        <BlogDate data={data.created_at} />       
        <BlogDuration data={plainText} />
      </div>     
      <div className={''}>        
        <ReactMarkdown
        children={data.content}
        transformImageUri={uri =>
          uri.startsWith("http") ? uri : `${process.env.REACT_IMAGE_BASE_URL}${uri}`
          }
        />
        
      
      </div>       
      </Container>      
    </div>
  )
}

export default BlogFull