import * as React from "react"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';



import {CalendarGray} from './../../assets/svgs'
const getOrdinal = (n)=>{
    let ord = ["st", "nd", "rd"]
    let exceptions = [11, 12, 13]
    let nth = 
    ord[(n % 10) - 1] == undefined || exceptions.includes(n % 100) ? "th" : ord[(n % 10) - 1]
    return n + nth
}
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const BlogDate = ({data}) => {
  const breakpoints = useBreakpoint();

  const timeStamp = Date.parse(data);

  const date = new Date(timeStamp);
  const day = getOrdinal(date.getDate());
  const month = date.getMonth();
  const year = date.getFullYear();
  
  return(
    <div>
      {!breakpoints.xs && <><CalendarGray /><span>-</span></>}<span>{day} {months[month]} {year}</span>
    </div>
  )
}
export default BlogDate