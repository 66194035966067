import React from 'react';

export const LooprLogo = () => {
  return (
    <>
      <svg
        className="loopr-logo"
        width="439"
        height="200"
        viewBox="0 0 439 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M243.478 100.194C243.478 132.917 216.988 159.408 184.265 159.408C172.344 159.408 161.203 155.902 151.931 149.824C147.568 146.942 143.595 143.514 140.088 139.696C133.31 132.138 128.48 122.789 126.298 112.427C127.778 108.687 128.635 104.557 128.635 100.272C128.635 95.987 127.778 91.8576 126.298 88.1178C123.883 82.1186 119.753 76.9764 114.455 73.3145C109.157 69.6526 102.768 67.549 95.9122 67.549C77.8365 67.549 63.1889 82.1965 63.1889 100.272C63.1889 118.348 77.8365 132.995 95.9122 132.995C102.846 132.995 109.235 130.892 114.455 127.23C117.65 135.644 122.402 143.28 128.246 149.902C118.974 155.98 107.833 159.486 95.9122 159.486C63.1889 159.486 36.6987 132.995 36.6987 100.272C36.6987 67.549 63.1889 41.0587 95.9122 41.0587C107.833 41.0587 118.974 44.5648 128.246 50.642C132.609 53.5247 136.582 56.9529 140.088 60.7706C146.867 68.3281 151.697 77.6776 153.879 88.0399C152.399 91.7797 151.542 95.9091 151.542 100.194C151.542 104.479 152.399 108.609 153.879 112.349C156.294 118.348 160.424 123.49 165.722 127.152C171.02 130.814 177.409 132.917 184.265 132.917C202.34 132.917 216.988 118.27 216.988 100.194C216.988 82.1186 202.34 67.4711 184.265 67.4711C177.409 67.4711 170.942 69.5747 165.722 73.2366C162.449 64.822 157.775 57.1866 151.931 50.5641C161.203 44.4869 172.344 40.9808 184.265 40.9808C216.91 40.9808 243.478 67.4711 243.478 100.194Z"
          fill="#3C4FE0"
        />
        <path d="M0 0V156.059H28.2043V0H0Z" fill="#3C4FE0" />
        <path
          d="M311.027 40.9808C299.652 40.9808 289.056 44.1752 280.018 49.707C263.033 60.1473 251.814 78.8463 251.814 100.194V200H280.018V150.604C289.056 156.135 299.652 159.33 311.027 159.33C343.751 159.33 370.241 132.84 370.241 100.116C370.241 67.4711 343.751 40.9808 311.027 40.9808ZM311.027 132.917C296.692 132.917 284.459 123.646 280.018 110.79C278.85 107.44 278.304 103.934 278.304 100.194C278.304 96.4545 278.927 92.9484 280.018 89.5982C284.381 76.7426 296.614 67.4711 311.027 67.4711C329.103 67.4711 343.751 82.1186 343.751 100.194C343.751 118.27 329.103 132.917 311.027 132.917Z"
          fill="#3C4FE0"
        />
        <path
          d="M378.266 43.4741H402.496V57.6541C405.145 52.0444 408.574 47.8372 412.937 44.9544C417.3 42.0716 422.052 40.5913 427.195 40.5913C430.856 40.5913 434.674 41.5263 438.648 43.4741L429.922 67.7827C426.649 66.1466 423.922 65.2895 421.741 65.2895C417.378 65.2895 413.716 68.0165 410.755 73.3924C407.794 78.7684 406.236 89.3645 406.236 105.025L406.314 110.479V155.98H378.266V43.4741Z"
          fill="#3C4FE0"
        />
      </svg>
    </>
  );
};

export const LooprLogoMin = () => {
  return (
    <>
      <svg
        className="loopr-logo-min"
        width="349"
        height="200"
        viewBox="0 0 349 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M348.981 99.9343C348.981 155.161 304.273 199.869 249.047 199.869C228.928 199.869 210.125 193.951 194.477 183.695C187.114 178.83 180.408 173.044 174.49 166.601C163.051 153.846 154.898 138.067 151.216 120.579C153.715 114.267 155.161 107.298 155.161 100.066C155.161 92.8337 153.715 85.8646 151.216 79.5529C147.14 69.428 140.171 60.7495 131.229 54.5694C122.288 48.3892 111.506 44.8389 99.9343 44.8389C69.428 44.8389 44.7074 69.5595 44.7074 100.066C44.7074 130.572 69.428 155.293 99.9343 155.293C111.637 155.293 122.419 151.742 131.229 145.562C136.621 159.763 144.642 172.65 154.504 183.826C138.856 194.083 120.053 200 99.9343 200C44.7074 200 0 155.293 0 100.066C0 44.8389 44.7074 0.131493 99.9343 0.131493C120.053 0.131493 138.856 6.04865 154.504 16.3051C161.867 21.1703 168.573 26.956 174.49 33.3991C185.93 46.1538 194.083 61.9329 197.765 79.4214C195.266 85.7331 193.82 92.7022 193.82 99.9343C193.82 107.166 195.266 114.135 197.765 120.447C201.841 130.572 208.81 139.25 217.751 145.431C226.693 151.611 237.475 155.161 249.047 155.161C279.553 155.161 304.273 130.44 304.273 99.9343C304.273 69.428 279.553 44.7074 249.047 44.7074C237.475 44.7074 226.561 48.2577 217.751 54.4379C212.229 40.2367 204.339 27.3504 194.477 16.1736C210.125 5.91716 228.928 0 249.047 0C304.273 0 348.981 44.7074 348.981 99.9343Z"
          fill="#3C4FE0"
        />
      </svg>
    </>
  );
};

export const ArrowDownWhite = () => {
  return (
    <>
      <svg
        className="arrow-down-white"
        xmlns="http://www.w3.org/2000/svg"
        width="39.039"
        height="58"
        viewBox="0 0 39.039 58"
      >
        <path
          id="Path_196"
          data-name="Path 196"
          d="M425.11,1016.254l19.045,17.941-19.045,17.643,19.045-17.643h-56"
          transform="translate(1053.838 -388.156) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="4"
        />
      </svg>
    </>
  );
};

export const ArrowRightBlack = () => {
  return (
    <>
      <svg
        className="arrow-right-black"
        xmlns="http://www.w3.org/2000/svg"
        width="15.208"
        height="10.209"
        viewBox="0 0 15.208 10.209"
      >
        <path
          id="Path_203"
          data-name="Path 203"
          d="M397.861,1016.255l5,4.712-5,4.634,5-4.634H388.156"
          transform="translate(-388.155 -1015.891)"
          fill="none"
          stroke="#1f1f1f"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </svg>
    </>
  );
};

export const LooprData = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="149"
        height="96.257"
        viewBox="0 0 149 96.257"
      >
        <g id="Group_302" data-name="Group 302" transform="translate(0.5 0.5)">
          <rect
            id="Rectangle_77"
            data-name="Rectangle 77"
            width="40.977"
            height="11.443"
            transform="translate(53.512 83.813)"
            fill="#69a3f7"
          />
          <path
            id="Path_114"
            data-name="Path 114"
            d="M260.511,49.892H125.243c-4.163,0-6.364,2.182-6.364,6.315v71.382a6.38,6.38,0,0,0,1.617,4.7,6.542,6.542,0,0,0,4.752,1.617c7.406-.02,45.283-.054,52.689-.069q6.893-.015,13.78,0h4.8v.074h64.095c4.04,0,6.266-2.246,6.266-6.315q.007-35.694,0-71.382C266.875,52.074,264.673,49.892,260.511,49.892Z"
            transform="translate(-118.879 -49.892)"
            fill="#cfeaff"
          />
          <path
            id="Path_115"
            data-name="Path 115"
            d="M267.183,64.423v65.5a4.231,4.231,0,0,1-4.231,4.231H133.515a4.227,4.227,0,0,1-4.226-4.231v-65.5a4.227,4.227,0,0,1,4.226-4.231H262.952A4.231,4.231,0,0,1,267.183,64.423Z"
            transform="translate(-124.173 -55.13)"
            fill="#fff"
          />
          <rect
            id="Rectangle_78"
            data-name="Rectangle 78"
            width="148"
            height="83.812"
            rx="10.732"
            transform="translate(0 0)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_79"
            data-name="Rectangle 79"
            width="40.977"
            height="11.444"
            transform="translate(53.512 83.812)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_57"
            data-name="Line 57"
            x2="54.981"
            transform="translate(46.509 95.257)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g
            id="Group_165"
            data-name="Group 165"
            transform="translate(16.723 12.229)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M245.957,74.775v49.862L209.365,98.273l-12.2,11.3-24.387-18-19.874,18v-34.8Z"
              transform="translate(-152.907 -74.775)"
              fill="#e1f1ff"
              stroke="#707070"
              strokeWidth="1"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M245.957,142.019V154H152.907V126.954l19.874-18,24.387,18,12.2-11.3Z"
              transform="translate(-152.907 -92.157)"
              fill="#69a3f7"
              stroke="#3c4fe0"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <path
              id="Path_118"
              data-name="Path 118"
              d="M245.957,74.775v49.862L209.365,98.273l-12.2,11.3-24.387-18-19.874,18v-34.8Z"
              transform="translate(-152.907 -74.775)"
              fill="none"
              stroke="#3c4fe0"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <circle
              id="Ellipse_47"
              data-name="Ellipse 47"
              cx="5.922"
              cy="5.922"
              r="5.922"
              transform="translate(72.613 10.071)"
              fill="#fff"
            />
          </g>
          <path
            id="Rectangle_80"
            data-name="Rectangle 80"
            d="M6.663,0H30.412a6.663,6.663,0,0,1,6.663,6.663v0a6.663,6.663,0,0,1-6.663,6.663H0a0,0,0,0,1,0,0V6.663A6.663,6.663,0,0,1,6.663,0Z"
            transform="translate(101.109 13.981)"
            fill="#69a3f7"
          />
          <path
            id="Rectangle_81"
            data-name="Rectangle 81"
            d="M7.509,0H47.684a7.509,7.509,0,0,1,7.509,7.509v0a7.509,7.509,0,0,1-7.509,7.509H0a0,0,0,0,1,0,0V7.509A7.509,7.509,0,0,1,7.509,0Z"
            transform="translate(82.99 39.033)"
            fill="#e1f1ff"
          />
          <line
            id="Line_58"
            data-name="Line 58"
            x2="26.072"
            transform="translate(107.328 21.694)"
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            id="Line_59"
            data-name="Line 59"
            x2="44.013"
            transform="translate(88.492 48.371)"
            fill="none"
            stroke="#3c4fe0"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const LooprModels = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147.999"
        height="95.612"
        viewBox="0 0 147.999 95.612"
      >
        <g id="Group_303" data-name="Group 303" transform="translate(0.5 0.5)">
          <rect
            id="Rectangle_74"
            data-name="Rectangle 74"
            width="40.699"
            height="14.706"
            transform="translate(53.15 79.906)"
            fill="#69a3f7"
          />
          <path
            id="Path_96"
            data-name="Path 96"
            d="M765.55,51.4H631.2c-4.134,0-6.321,2.167-6.321,6.272v70.9a6.337,6.337,0,0,0,1.606,4.666,6.5,6.5,0,0,0,4.72,1.606c7.356-.02,44.975-.054,52.331-.068q6.846-.015,13.687,0h4.764v.073h63.661c4.017,0,6.224-2.231,6.224-6.272q.015-35.452,0-70.9C771.872,53.569,769.685,51.4,765.55,51.4Zm1.372,74.287a4.2,4.2,0,0,1-4.2,4.2H634.158a4.2,4.2,0,0,1-4.2-4.2V60.632a4.2,4.2,0,0,1,4.2-4.2H762.719a4.2,4.2,0,0,1,4.2,4.2Z"
            transform="translate(-624.879 -51.402)"
            fill="#cfeaff"
          />
          <path
            id="Path_97"
            data-name="Path 97"
            d="M772.251,65.9v65.057a4.2,4.2,0,0,1-4.2,4.2H639.487a4.2,4.2,0,0,1-4.2-4.2V65.9a4.2,4.2,0,0,1,4.2-4.2H768.048A4.2,4.2,0,0,1,772.251,65.9Z"
            transform="translate(-630.208 -56.674)"
            fill="#fff"
          />
          <rect
            id="Rectangle_75"
            data-name="Rectangle 75"
            width="146.999"
            height="83.246"
            rx="10.732"
            transform="translate(0 0)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_76"
            data-name="Rectangle 76"
            width="40.699"
            height="11.367"
            transform="translate(53.15 83.246)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_55"
            data-name="Line 55"
            x2="54.609"
            transform="translate(46.195 94.612)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_98"
            data-name="Path 98"
            d="M844.4,88.7l-11.253-4.6-8.632,3.693L824.6,99.97l10.495,4.78,9.446-3.843Z"
            transform="translate(-727.069 -68.14)"
            fill="#cfeaff"
          />
          <path
            id="Path_99"
            data-name="Path 99"
            d="M734.388,89.457l-21.656,8.767V123.2l21.679,8.306,21.634-8.715-.1-24.568Z"
            transform="translate(-669.849 -70.882)"
            fill="#cfeaff"
          />
          <path
            id="Path_100"
            data-name="Path 100"
            d="M734.534,139.415V102.4l29.807-12.655"
            transform="translate(-681.009 -71.028)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_101"
            data-name="Path 101"
            d="M712.731,130.971l.142-36.567,30.518-12.41"
            transform="translate(-669.849 -67.061)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_102"
            data-name="Path 102"
            d="M747.808,139.491V102.677L717.345,90.289"
            transform="translate(-672.21 -71.308)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_103"
            data-name="Path 103"
            d="M769.36,130.9l-.1-36.493-30.518-12.41"
            transform="translate(-683.164 -67.061)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_104"
            data-name="Path 104"
            d="M805.591,107.418"
            transform="translate(-717.382 -80.075)"
            fill="#3c4fe0"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            id="Path_105"
            data-name="Path 105"
            d="M778.377,119.23l8.938-3.635v24.649l-30.955,12.7.257-24.917,11.162-4.4V111.445l10.5-4.027Z"
            transform="translate(-692.181 -80.075)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_106"
            data-name="Path 106"
            d="M694.511,99.168v37.265l30.19,12.288.257-24.917V111.457l-10.911-4.235Z"
            transform="translate(-660.522 -75.852)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_107"
            data-name="Path 107"
            d="M725.083,73.7l19.259,7.832-9.505,3.836,11.783,4.792-10.5,4.027L724.958,98.42l-10.911-4.235-19.536-8.054Z"
            transform="translate(-660.522 -62.815)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_108"
            data-name="Path 108"
            d="M694.511,120.628l30.572,11.713,30.572-12.432-8.938-4.064"
            transform="translate(-660.522 -84.389)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_109"
            data-name="Path 109"
            d="M694.511,150.853l30.572,11.713,30.572-12.432"
            transform="translate(-660.522 -101.941)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_110"
            data-name="Path 110"
            d="M694.511,175.08l30.19,12.288,30.955-12.7"
            transform="translate(-660.522 -114.499)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_111"
            data-name="Path 111"
            d="M845.934,76.243,837.3,79.936l10.725,4.633,9.16-3.725Z"
            transform="translate(-733.614 -64.118)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_112"
            data-name="Path 112"
            d="M858.973,101.817l.147-12.424,9.16-3.725.139,12.2Z"
            transform="translate(-744.707 -68.942)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_113"
            data-name="Path 113"
            d="M837.3,83.808l.083,12.175,10.495,4.881.147-12.424Z"
            transform="translate(-733.614 -67.99)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_56"
            data-name="Line 56"
            y2="7.57"
            transform="translate(83.82 18.715)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const DatasetsIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35.218"
        height="26.556"
        viewBox="0 0 35.218 26.556"
      >
        <g id="Group_302" data-name="Group 302" transform="translate(0.5 0.5)">
          <path
            id="Path_58"
            data-name="Path 58"
            d="M418.221,217.546l-.953-2.735H409.83v14.766h20.191V217.546Z"
            transform="translate(-409.83 -204.022)"
            fill="#d3eaff"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M422.047,211.313l-.953-2.735H409.83V226.32h24.017V211.313Z"
            transform="translate(-409.83 -200.765)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_60"
            data-name="Path 60"
            d="M439.43,218.137h5.1V203.13h-11.8l-.953-2.735H420.514v3.585"
            transform="translate(-415.413 -196.489)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M431.2,196.119v-3.907h11.264l.953,2.735h11.8v15.007h-5.1"
            transform="translate(-420.996 -192.212)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <text
            id="_..."
            data-name="..."
            transform="translate(14.666 21.875)"
            fill="#3c4fe0"
            fontSize="10"
            fontFamily="Helvetica"
          >
            <tspan x="0" y="0">
              ...
            </tspan>
          </text>
        </g>
      </svg>
    </>
  );
};

export const AnnotateIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.406"
        height="30.417"
        viewBox="0 0 34.406 30.417"
      >
        <g id="Group_303" data-name="Group 303" transform="translate(0 0.5)">
          <path
            id="Path_62"
            data-name="Path 62"
            d="M687.08,219.48v-3.347h-8.406l2.857.633,3.008,2.714Z"
            transform="translate(-661.955 -204.794)"
            fill="#d3eaff"
          />
          <rect
            id="Rectangle_39"
            data-name="Rectangle 39"
            width="25.126"
            height="3.581"
            transform="translate(0 1.671)"
            fill="#d3eaff"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M658.726,219.746l-15.07-.266v-3.315l13.995-.032Z"
            transform="translate(-643.656 -204.794)"
            fill="#d3eaff"
          />
          <path
            id="Path_64"
            data-name="Path 64"
            d="M667.658,239.043l-24,.056v-3.583H663.75Z"
            transform="translate(-643.656 -214.924)"
            fill="#d3eaff"
          />
          <rect
            id="Rectangle_40"
            data-name="Rectangle 40"
            width="6.053"
            height="19.025"
            transform="translate(33.7 24.717) rotate(132.062)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_65"
            data-name="Path 65"
            d="M672.691,215.2l5.713,1.079-4.055,4.494Z"
            transform="translate(-658.828 -204.307)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_21"
            data-name="Line 21"
            x1="1.619"
            y2="1.794"
            transform="translate(14.524 11.324)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_41"
            data-name="Rectangle 41"
            width="26.26"
            height="5.252"
            rx="2"
            transform="translate(1.432 0)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_66"
            data-name="Path 66"
            d="M667.807,217.395h3.847a1.262,1.262,0,0,0,1.262-1.262v-2.319a1.671,1.671,0,0,0-1.671-1.671H648.327a1.671,1.671,0,0,0-1.671,1.671v1.91a1.671,1.671,0,0,0,1.671,1.671h11.887"
            transform="translate(-645.224 -202.71)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_67"
            data-name="Path 67"
            d="M669.225,237.154h-20.9a1.671,1.671,0,0,1-1.671-1.671v-1.91a1.671,1.671,0,0,1,1.671-1.671h15.078"
            transform="translate(-645.224 -213.035)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const ModelTrainingIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="39.933"
        height="35.278"
        viewBox="0 0 39.933 35.278"
      >
        <g id="Group_304" data-name="Group 304" transform="translate(0.68 0.5)">
          <path
            id="Path_68"
            data-name="Path 68"
            d="M438.272,352.2l-7.451,4.3v8.993l7.521,4.586,7.577-4.586V356.6Z"
            transform="translate(-419.212 -339.007)"
            fill="#d3eaff"
          />
          <path
            id="Path_69"
            data-name="Path 69"
            d="M431.441,380.032l-9.053-5.578v-10.66l9.053,5.578Z"
            transform="translate(-414.303 -345.754)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_70"
            data-name="Path 70"
            d="M453.057,380.032l9.331-5.578v-10.66l-9.331,5.578Z"
            transform="translate(-432.157 -345.754)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_71"
            data-name="Path 71"
            d="M426.084,348.169l9.528,5.838,9.528-5.838-9.672-5.51Z"
            transform="translate(-416.454 -333.449)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_72"
            data-name="Path 72"
            d="M445.464,320.619v7.1l2.42-2.421-2.42,2.421-2.457-2.384"
            transform="translate(-426.307 -320.619)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_73"
            data-name="Path 73"
            d="M403.037,394.941l5.98-3.3-3.289-.948,3.289.948-.9,3.3"
            transform="translate(-403.037 -361.412)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_74"
            data-name="Path 74"
            d="M487.024,394.941l-5.98-3.3,3.289-.948-3.289.948.9,3.3"
            transform="translate(-448.451 -361.412)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const OptimiseIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32.908"
        height="31.904"
        viewBox="0 0 32.908 31.904"
      >
        <g id="Group_305" data-name="Group 305" transform="translate(0.5 0.5)">
          <path
            id="Path_75"
            data-name="Path 75"
            d="M672.9,363.538v8.544H652.728V350.3h23.256v13.243Z"
            transform="translate(-652.728 -343.708)"
            fill="#d3eaff"
          />
          <path
            id="Path_76"
            data-name="Path 76"
            d="M672.9,368.752H652.728V340.379H681.1V359.55"
            transform="translate(-652.728 -340.379)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_42"
            data-name="Rectangle 42"
            width="11.733"
            height="11.733"
            transform="translate(20.176 19.171)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_22"
            data-name="Line 22"
            y2="11.262"
            transform="translate(5.311 12.855)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_23"
            data-name="Line 23"
            y2="2.713"
            transform="translate(5.311 4.908)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_24"
            data-name="Line 24"
            y2="3.479"
            transform="translate(11.293 20.638)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_25"
            data-name="Line 25"
            y2="10.496"
            transform="translate(11.293 4.908)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_26"
            data-name="Line 26"
            y2="7.956"
            transform="translate(17.274 16.161)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_27"
            data-name="Line 27"
            y2="6.019"
            transform="translate(17.274 4.908)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_28"
            data-name="Line 28"
            y2="7.349"
            transform="translate(23.256 11.822)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_29"
            data-name="Line 29"
            y2="1.68"
            transform="translate(23.256 4.908)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_43"
            data-name="Rectangle 43"
            width="3.444"
            height="5.234"
            transform="translate(3.686 7.621)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_44"
            data-name="Rectangle 44"
            width="3.444"
            height="5.234"
            transform="translate(9.571 15.404)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_45"
            data-name="Rectangle 45"
            width="3.444"
            height="5.234"
            transform="translate(15.552 10.927)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_46"
            data-name="Rectangle 46"
            width="3.444"
            height="5.234"
            transform="translate(21.534 6.588)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_77"
            data-name="Path 77"
            d="M697.394,389.262l1.994,2.147,5.567-5.567"
            transform="translate(-675.289 -363.343)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const DeployIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30.953"
        height="32.151"
        viewBox="0 0 30.953 32.151"
      >
        <g id="Group_306" data-name="Group 306" transform="translate(0.526)">
          <path
            id="Path_78"
            data-name="Path 78"
            d="M445.5,473.423l-1.863.148a17.594,17.594,0,0,0-11.045,5.1l-13.067,13.067,7.662,7.662,13.067-13.067a17.6,17.6,0,0,0,5.1-11.046Z"
            transform="translate(-417.639 -473.423)"
            fill="#d3eaff"
          />
          <path
            id="Path_79"
            data-name="Path 79"
            d="M453.919,477.181l-1.759.14a16.61,16.61,0,0,0-10.427,4.812L430.977,492.89l7.233,7.233,10.757-10.757a16.61,16.61,0,0,0,4.812-10.427Z"
            transform="translate(-423.992 -475.507)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_80"
            data-name="Path 80"
            d="M428.236,498.584a13.252,13.252,0,0,0-12.735,4.633.809.809,0,0,0,.8,1.315,10.172,10.172,0,0,1,5.89.092Z"
            transform="translate(-415.295 -487.256)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_81"
            data-name="Path 81"
            d="M453.343,515.369a13.251,13.251,0,0,1-4.632,12.735.809.809,0,0,1-1.315-.805,10.171,10.171,0,0,0-.092-5.89Z"
            transform="translate(-433.046 -496.685)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_30"
            data-name="Line 30"
            x2="7.306"
            y2="7.306"
            transform="translate(8.996 15.373)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_12"
            data-name="Ellipse 12"
            cx="1.549"
            cy="1.549"
            rx="1.549"
            ry="1.549"
            transform="translate(21.608 6.896)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_31"
            data-name="Line 31"
            x1="7.897"
            y2="7.897"
            transform="translate(1.099 22.062)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_32"
            data-name="Line 32"
            x1="4.599"
            y2="4.599"
            transform="translate(2.307 19.971)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            id="Line_33"
            data-name="Line 33"
            x1="4.599"
            y2="4.599"
            transform="translate(7.416 25.081)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const RetrainIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29.719"
        height="30.534"
        viewBox="0 0 29.719 30.534"
      >
        <g id="Group_307" data-name="Group 307" transform="translate(0.5 0.5)">
          <ellipse
            id="Ellipse_13"
            data-name="Ellipse 13"
            cx="2.89"
            cy="2.89"
            rx="2.89"
            ry="2.89"
            transform="translate(3.647 3.558)"
            fill="#d3eaff"
          />
          <ellipse
            id="Ellipse_14"
            data-name="Ellipse 14"
            cx="2.89"
            cy="2.89"
            rx="2.89"
            ry="2.89"
            transform="translate(3.647 12.046)"
            fill="#d3eaff"
          />
          <ellipse
            id="Ellipse_15"
            data-name="Ellipse 15"
            cx="2.89"
            cy="2.89"
            rx="2.89"
            ry="2.89"
            transform="translate(3.647 20.547)"
            fill="#d3eaff"
          />
          <g id="Group_127" data-name="Group 127" transform="translate(0 0)">
            <ellipse
              id="Ellipse_16"
              data-name="Ellipse 16"
              cx="1.139"
              cy="1.139"
              rx="1.139"
              ry="1.139"
              transform="translate(0 0.235)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_17"
              data-name="Ellipse 17"
              cx="1.139"
              cy="1.139"
              rx="1.139"
              ry="1.139"
              transform="translate(0 27.016)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_18"
              data-name="Ellipse 18"
              cx="1.139"
              cy="1.139"
              rx="1.139"
              ry="1.139"
              transform="translate(0 9.355)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_19"
              data-name="Ellipse 19"
              cx="1.139"
              cy="1.139"
              rx="1.139"
              ry="1.139"
              transform="translate(0 17.819)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_20"
              data-name="Ellipse 20"
              cx="1.147"
              cy="1.147"
              rx="1.147"
              ry="1.147"
              transform="translate(7.154 5.131)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_21"
              data-name="Ellipse 21"
              cx="1.147"
              cy="1.147"
              rx="1.147"
              ry="1.147"
              transform="translate(7.154 13.578)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <ellipse
              id="Ellipse_22"
              data-name="Ellipse 22"
              cx="1.147"
              cy="1.147"
              rx="1.147"
              ry="1.147"
              transform="translate(7.154 22.042)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_82"
              data-name="Path 82"
              d="M674.729,525.768H686.4l5.561,5.138"
              transform="translate(-665.28 -502.752)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              id="Line_34"
              data-name="Line 34"
              x2="17.099"
              y2="0.113"
              transform="translate(9.577 14.612)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_83"
              data-name="Path 83"
              d="M674.729,479.77h11.289l5.81-5.409"
              transform="translate(-665.151 -473.339)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_84"
              data-name="Path 84"
              d="M659.777,502.163l4.142-4.221-4.142-4.221,4.142-4.221-4.142-4.221,4.142-4.221-4.257-4.11"
              transform="translate(-657.197 -474.774)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_85"
              data-name="Path 85"
              d="M659.921,515.953h18.6l5.5,2.3"
              transform="translate(-657.341 -497.033)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_86"
              data-name="Path 86"
              d="M659.921,492.673h18.307l5.789-2.93"
              transform="translate(-657.341 -482.129)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_47"
              data-name="Rectangle 47"
              width="2.043"
              height="2.043"
              transform="translate(26.676)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_48"
              data-name="Rectangle 48"
              width="2.043"
              height="2.043"
              transform="translate(26.676 6.634)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_49"
              data-name="Rectangle 49"
              width="2.043"
              height="2.043"
              transform="translate(26.676 13.916)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_50"
              data-name="Rectangle 50"
              width="2.043"
              height="2.043"
              transform="translate(26.676 20.463)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <rect
              id="Rectangle_51"
              data-name="Rectangle 51"
              width="2.043"
              height="2.043"
              transform="translate(26.676 27.491)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export const UspQuality = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.876"
        height="39.594"
        viewBox="0 0 33.876 39.594"
      >
        <g id="Group_146" data-name="Group 146" transform="translate(0 0.5)">
          <path
            id="Path_37"
            data-name="Path 37"
            d="M120.7,75.346l.9-.9a3.684,3.684,0,0,0,0-5.21l-.9-.9a3.685,3.685,0,0,1-1.079-2.605V64.446a3.684,3.684,0,0,0-3.684-3.684h-1.278a3.683,3.683,0,0,1-2.605-1.079l-.9-.9a3.683,3.683,0,0,0-5.21,0l-.9.9a3.682,3.682,0,0,1-2.6,1.079H101.15a3.684,3.684,0,0,0-3.684,3.684v1.278a3.684,3.684,0,0,1-1.079,2.605l-.9.9a3.684,3.684,0,0,0,0,5.21l.9.9a3.685,3.685,0,0,1,1.079,2.605v1.278a3.684,3.684,0,0,0,3.684,3.684h1.278a3.682,3.682,0,0,1,2.6,1.079l.9.9a3.683,3.683,0,0,0,5.21,0l.9-.9a3.682,3.682,0,0,1,2.605-1.079h1.278a3.684,3.684,0,0,0,3.684-3.684V77.951A3.685,3.685,0,0,1,120.7,75.346Z"
            transform="translate(-94.405 -54.719)"
            fill="#d3eaff"
          />
          <path
            id="Path_38"
            data-name="Path 38"
            d="M126.656,69.58l.9-.9a3.684,3.684,0,0,0,0-5.21l-.9-.9a3.682,3.682,0,0,1-1.079-2.605V58.68A3.684,3.684,0,0,0,121.892,55h-1.278a3.685,3.685,0,0,1-2.605-1.079l-.9-.9a3.684,3.684,0,0,0-5.21,0l-.9.9A3.685,3.685,0,0,1,108.387,55h-1.278a3.684,3.684,0,0,0-3.684,3.684v1.278a3.683,3.683,0,0,1-1.079,2.605l-.9.9a3.684,3.684,0,0,0,0,5.21l.9.9a3.684,3.684,0,0,1,1.079,2.605v1.278a3.684,3.684,0,0,0,3.684,3.684h1.278a3.682,3.682,0,0,1,2.605,1.079l.9.9a3.684,3.684,0,0,0,5.21,0l.9-.9a3.683,3.683,0,0,1,2.605-1.079h1.278a3.684,3.684,0,0,0,3.684-3.684V72.185A3.683,3.683,0,0,1,126.656,69.58Z"
            transform="translate(-97.283 -51.934)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g
            id="Group_108"
            data-name="Group 108"
            transform="translate(1.061 23.735)"
          >
            <path
              id="Path_39"
              data-name="Path 39"
              d="M139.624,97.846l5.819,10.079-6.4-1.52-1.882,6.3-6.192-10.726"
              transform="translate(-113.129 -97.846)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <path
              id="Path_40"
              data-name="Path 40"
              d="M102.276,97.846l-5.819,10.079,6.4-1.52,1.882,6.3,6.193-10.726"
              transform="translate(-96.457 -97.846)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
          <path
            id="Path_41"
            data-name="Path 41"
            d="M121.047,77.885l2.78,2.78,5.248-5.248"
            transform="translate(-107.274 -63.277)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_42"
            data-name="Path 42"
            d="M127.019,72.926l.592-.592a2.413,2.413,0,0,0,0-3.413l-.592-.592a2.413,2.413,0,0,1-.707-1.707v-.838a2.413,2.413,0,0,0-2.413-2.413h-.837a2.415,2.415,0,0,1-1.707-.707l-.592-.592a2.414,2.414,0,0,0-3.413,0l-.592.592a2.415,2.415,0,0,1-1.707.707h-.838a2.413,2.413,0,0,0-2.413,2.413v.838a2.415,2.415,0,0,1-.707,1.707l-.592.592a2.413,2.413,0,0,0,0,3.413l.592.592a2.415,2.415,0,0,1,.707,1.707v.837a2.413,2.413,0,0,0,2.413,2.413h.838a2.413,2.413,0,0,1,1.707.707l.592.592a2.414,2.414,0,0,0,3.413,0l.592-.592a2.413,2.413,0,0,1,1.707-.707h.837a2.413,2.413,0,0,0,2.413-2.413v-.837A2.413,2.413,0,0,1,127.019,72.926Z"
            transform="translate(-101.839 -56.49)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            strokeDasharray="2 7"
          />
        </g>
      </svg>
    </>
  );
};

export const UspScalability = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.748"
        height="34.713"
        viewBox="0 0 34.748 34.713"
      >
        <g id="Group_147" data-name="Group 147" transform="translate(0 0.5)">
          <rect
            id="Rectangle_18"
            data-name="Rectangle 18"
            width="22.549"
            height="29.736"
            rx="2"
            transform="translate(0 4.475)"
            fill="#d3eaff"
          />
          <rect
            id="Rectangle_19"
            data-name="Rectangle 19"
            width="17.412"
            height="17.412"
            transform="translate(0.534 16.301)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M193.227,59.74V51.934H226.94V85.647h-7.805"
            transform="translate(-192.693 -51.934)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            id="Rectangle_20"
            data-name="Rectangle 20"
            width="25.907"
            height="25.907"
            transform="translate(0.534 7.805)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            strokeDasharray="3 6"
          />
          <g
            id="Group_111"
            data-name="Group 111"
            transform="translate(22.307 8.495) rotate(45)"
          >
            <path
              id="Path_44"
              data-name="Path 44"
              d="M0,0V8.585"
              transform="translate(2.621 0)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeWidth="1"
            />
            <path
              id="Path_45"
              data-name="Path 45"
              d="M0,2.622,2.621,0,5.243,2.622"
              transform="translate(0 0)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export const UspCostEffective = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41.144"
        height="42.48"
        viewBox="0 0 41.144 42.48"
      >
        <g id="Group_148" data-name="Group 148" transform="translate(0.5 0.5)">
          <ellipse
            id="Ellipse_8"
            data-name="Ellipse 8"
            cx="5.55"
            cy="5.55"
            rx="5.55"
            ry="5.55"
            transform="translate(9.69 1.93)"
            fill="#d3eaff"
          />
          <path
            id="Path_46"
            data-name="Path 46"
            d="M344.742,86.369c0-6.693-6.922-12.119-15.462-12.119-7.1,0-13.075,3.761-14.885,8.879h-1.405a2,2,0,0,0-2,2v2.04a4.247,4.247,0,0,0,4.247,4.247h0a13.131,13.131,0,0,0,4.444,4.444l2.023,4.118a1.894,1.894,0,0,0,1.7,1.059h2.426a.926.926,0,0,0,.926-.926l-.808-1.911a19.124,19.124,0,0,0,7.1-.087l-.809,2a.926.926,0,0,0,.926.926h2.55a1.657,1.657,0,0,0,1.5-.957l2.1-4.5C342.631,93.359,344.742,90.06,344.742,86.369Z"
            transform="translate(-310.99 -59.556)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_47"
            data-name="Path 47"
            d="M337.643,79.855a11.894,11.894,0,0,1,7.373,0"
            transform="translate(-323.138 -61.844)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            d="M325.368,90.379a1.726,1.726,0,1,1,3.448,0"
            transform="translate(-317.543 -66.084)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_9"
            data-name="Ellipse 9"
            cx="5.425"
            cy="5.425"
            rx="5.425"
            ry="5.425"
            transform="translate(12.802 0)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            id="Path_49"
            data-name="Path 49"
            d="M373.009,93.035c2.048.19,3.882,0,4.963-1.55a1.668,1.668,0,0,0-.193-2.255,1.579,1.579,0,0,0-.368-.222,1.767,1.767,0,0,0-2.325,2.026,4.918,4.918,0,0,0,4.244,3.713"
            transform="translate(-339.256 -66.221)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <g
            id="Group_112"
            data-name="Group 112"
            transform="translate(16.593 1.563)"
          >
            <path
              id="Path_50"
              data-name="Path 50"
              d="M344.335,51.737a2.067,2.067,0,0,0-2.367,0h0a1.069,1.069,0,0,0,0,1.8l2.226,1.69a1.208,1.208,0,0,1,0,2.031h0a2.335,2.335,0,0,1-2.674,0"
              transform="translate(-341.478 -50.688)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              id="Line_17"
              data-name="Line 17"
              y2="0.904"
              transform="translate(1.635 6.989)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
            <line
              id="Line_18"
              data-name="Line 18"
              y2="0.679"
              transform="translate(1.635)"
              fill="none"
              stroke="#3c4fe0"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export const EnvelopeWhite = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.122"
        height="10"
        viewBox="0 0 16.122 10"
      >
        <g
          id="Group_67"
          data-name="Group 67"
          transform="translate(-223.878 -5022.332)"
        >
          <g
            id="Rectangle_11"
            data-name="Rectangle 11"
            transform="translate(224 5022.332)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          >
            <rect width="16" height="10" stroke="none" />
            <rect x="0.5" y="0.5" width="15" height="9" fill="none" />
          </g>
          <path
            id="Path_10"
            data-name="Path 10"
            d="M365.355,5431.867l7.942,4.66,7.624-5.189"
            transform="translate(-141.224 -408.465)"
            fill="none"
            stroke="#fff"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const TelephoneWhite = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.607"
        height="17.739"
        viewBox="0 0 15.607 17.739"
      >
        <path
          id="Path_26"
          data-name="Path 26"
          d="M3.279,4.628A5.7,5.7,0,0,0,4.07,8.636a5.459,5.459,0,0,0,3.269,1.951s2.531-1.74,3.8,0,0,3.058-1.424,3.69-5.319-.89-7.8-4.165S-.171,2.178.552,1.136s3.1-1.924,4.256,0S3.279,4.628,3.279,4.628Z"
          transform="matrix(0.978, -0.208, 0.208, 0.978, 0.603, 3.022)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
      </svg>
    </>
  );
};

export const LinkedinIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.569"
        height="17.504"
        viewBox="0 0 17.569 17.504"
      >
        <g id="Group_114" data-name="Group 114" transform="translate(0.238)">
          <path
            id="Path_51"
            data-name="Path 51"
            d="M92.115,546.993c.133-.124.23-.224.336-.314a3.976,3.976,0,0,1,2.619-.954,5.247,5.247,0,0,1,1.86.214,3.919,3.919,0,0,1,2.639,2.959,4.418,4.418,0,0,1,.106.991c.009,2.443,0,4.887,0,7.33a.624.624,0,0,1-.677.674q-1.332,0-2.664,0a.63.63,0,0,1-.713-.707q0-2.969,0-5.939a4.4,4.4,0,0,0-.055-.674,1.308,1.308,0,0,0-.951-1.051,1.6,1.6,0,0,0-2.2,1.487c-.008.13-.006.26-.006.39v5.82a.59.59,0,0,1-.677.673q-1.5,0-3,0a.581.581,0,0,1-.664-.664V546.71c0-.472.219-.69.695-.691h2.664a.618.618,0,0,1,.691.688C92.115,546.79,92.115,546.872,92.115,546.993Zm4.343,10.323,2.4.006a.233.233,0,0,0,.233-.232c0-1.016.013-5.1.011-7.2a3.651,3.651,0,0,0-.043-.572,3.4,3.4,0,0,0-2.219-2.8,4.394,4.394,0,0,0-1.738-.213,3.852,3.852,0,0,0-2.754,1.284c-.191.232-.407.476-.61.721a.1.1,0,0,1-.176-.064c0-.482,0-1.135.005-1.452a.187.187,0,0,0-.187-.188h-2.5a.187.187,0,0,0-.187.187V557.12a.182.182,0,0,0,.182.182h2.81a.182.182,0,0,0,.181-.182c0-.723,0-3.419,0-4.783,0-.469-.007-.939.011-1.408a2.206,2.206,0,0,1,2.135-2.1,2.079,2.079,0,0,1,2.04,1.315A3.54,3.54,0,0,1,96.2,551.2c.018,1.68.026,4.987.029,5.888A.232.232,0,0,0,96.458,557.316Z"
            transform="translate(-82.584 -540.634)"
            fill="#3c4fe0"
            stroke="#3c4fe0"
            strokeMiterlimit="10"
            strokeWidth="0.477"
          />
          <path
            id="Path_52"
            data-name="Path 52"
            d="M76.017,552.283q0-2.451,0-4.9a1.349,1.349,0,0,1,.062-.466.772.772,0,0,1,.749-.539q1.2-.014,2.392,0a.876.876,0,0,1,.845.817c0,.062,0,.124,0,.186v9.872a.9.9,0,0,1-1,1c-.684,0-1.369-.013-2.053,0a.946.946,0,0,1-1.009-1.014C76.028,555.585,76.017,553.934,76.017,552.283Zm3.476.034v-4.952c0-.313-.1-.415-.418-.415H77.006c-.328,0-.408.079-.408.411q0,4.944,0,9.888c0,.316.1.418.415.418h2.069c.321,0,.411-.093.411-.415Z"
            transform="translate(-76.012 -540.985)"
            fill="#3c4fe0"
            stroke="#3c4fe0"
            strokeMiterlimit="10"
            strokeWidth="0.477"
          />
          <path
            id="Path_54"
            data-name="Path 54"
            d="M80.732,538.249a2.032,2.032,0,1,1-4.063-.059,2.032,2.032,0,0,1,4.063.059Z"
            transform="translate(-76.669 -536.19)"
            fill="#3c4fe0"
          />
        </g>
      </svg>
    </>
  );
};
export const FacebookIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.422"
        height="16.614"
        viewBox="0 0 9.422 16.614"
      >
        <g
          id="Group_115"
          data-name="Group 115"
          transform="translate(0.496 0.499)"
        >
          <path
            id="Path_55"
            data-name="Path 55"
            d="M131.377,545.92h-.227c-.56,0-1.119,0-1.679,0-.34,0-.439-.095-.441-.429q-.008-1.163,0-2.328c0-.335.105-.429.44-.43.56,0,1.119,0,1.679,0h.229c0-.092,0-.166,0-.24.013-.724-.013-1.452.048-2.172a3.429,3.429,0,0,1,3.368-3.2c.762-.029,1.526-.012,2.289-.01.255,0,.364.108.365.36q.006,1.135,0,2.271c0,.259-.106.362-.38.369-.515.013-1.03.013-1.545.021-.6.009-.836.234-.846.831-.011.565,0,1.13,0,1.717.078,0,.145.01.211.01.661,0,1.323,0,1.984,0,.408,0,.5.1.5.509q0,1.126,0,2.251c0,.353-.087.442-.438.444-.674,0-1.348,0-2.023,0h-.238v.252q0,3,0,5.991c0,.512-.069.58-.584.58h-2.137c-.509,0-.576-.065-.576-.565V545.92Z"
            transform="translate(-129.026 -537.105)"
            fill="none"
            stroke="#3c4fe0"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};
export const InstagramIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.283"
        height="18.283"
        viewBox="0 0 18.283 18.283"
      >
        <g id="Group_116" data-name="Group 116" transform="translate(0.5 0.5)">
          <rect
            id="Rectangle_30"
            data-name="Rectangle 30"
            width="17.284"
            height="17.283"
            rx="3"
            transform="translate(0 0)"
            fill="none"
            stroke="#3c4fe0"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_10"
            data-name="Ellipse 10"
            cx="4.226"
            cy="4.226"
            rx="4.226"
            ry="4.226"
            transform="translate(4.416 4.416)"
            fill="none"
            stroke="#3c4fe0"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <ellipse
            id="Ellipse_11"
            data-name="Ellipse 11"
            cx="1.137"
            cy="1.137"
            rx="1.137"
            ry="1.137"
            transform="translate(12.614 2.396)"
            fill="#3c4fe0"
            stroke="#3c4fe0"
            strokeWidth="1"
          />
        </g>
      </svg>
    </>
  );
};

export const Images = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70.259"
        height="61"
        viewBox="0 0 70.259 61"
      >
        <g
          id="Group_320"
          data-name="Group 320"
          transform="translate(-1029 -1753.015)"
        >
          <rect
            id="Rectangle_184"
            data-name="Rectangle 184"
            width="64"
            height="54"
            transform="translate(1029 1760.015)"
            fill="#d3eaff"
          />
          <path
            id="Union_6"
            data-name="Union 6"
            d="M.17,39.136,0,19.315,21.145,0,34.424,15.715l8.314-6.362L61.564,29.685v9.449H54.212l0,0Z"
            transform="translate(1036.695 1767.774)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
          <circle
            id="Ellipse_64"
            data-name="Ellipse 64"
            cx="5.5"
            cy="5.5"
            r="5.5"
            transform="translate(1082 1760.015)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
          <g
            id="Rectangle_228"
            data-name="Rectangle 228"
            transform="translate(1035 1753.015)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          >
            <rect width="64" height="55" stroke="none" />
            <rect x="1" y="1" width="62" height="53" fill="none" />
          </g>
        </g>
      </svg>
    </>
  );
};

export const Accuracy = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80.568"
        height="78.383"
        viewBox="0 0 80.568 78.383"
      >
        <g
          id="Group_321"
          data-name="Group 321"
          transform="translate(-293.815 -405.801)"
        >
          <path
            id="Subtraction_9"
            data-name="Subtraction 9"
            d="M33.686,67.367A33.692,33.692,0,0,1,20.574,2.647,33.692,33.692,0,0,1,46.8,64.72,33.483,33.483,0,0,1,33.686,67.367Zm0-57.742a24.06,24.06,0,1,0,24.061,24.06A24.087,24.087,0,0,0,33.686,9.625Z"
            transform="translate(293.815 416.815)"
            fill="#d3eaff"
          />
          <g
            id="Ellipse_76"
            data-name="Ellipse 76"
            transform="translate(293.816 416.816)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          >
            <ellipse
              cx="33.683"
              cy="33.684"
              rx="33.683"
              ry="33.684"
              stroke="none"
            />
            <ellipse
              cx="33.683"
              cy="33.684"
              rx="32.683"
              ry="32.684"
              fill="none"
            />
          </g>
          <g
            id="Ellipse_77"
            data-name="Ellipse 77"
            transform="translate(303.44 426.44)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          >
            <ellipse
              cx="24.059"
              cy="24.06"
              rx="24.059"
              ry="24.06"
              stroke="none"
            />
            <ellipse
              cx="24.059"
              cy="24.06"
              rx="23.059"
              ry="23.06"
              fill="none"
            />
          </g>
          <g
            id="Ellipse_78"
            data-name="Ellipse 78"
            transform="translate(315.203 438.203)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          >
            <ellipse
              cx="12.297"
              cy="12.297"
              rx="12.297"
              ry="12.297"
              stroke="none"
            />
            <ellipse
              cx="12.297"
              cy="12.297"
              rx="11.297"
              ry="11.297"
              fill="none"
            />
          </g>
          <g
            id="Ellipse_80"
            data-name="Ellipse 80"
            transform="translate(315.203 438.203)"
            fill="#d3eaff"
            stroke="#3c4fe0"
            strokeWidth="2"
          >
            <ellipse
              cx="12.297"
              cy="12.297"
              rx="12.297"
              ry="12.297"
              stroke="none"
            />
            <ellipse
              cx="12.297"
              cy="12.297"
              rx="11.297"
              ry="11.297"
              fill="none"
            />
          </g>
          <g
            id="Union_7"
            data-name="Union 7"
            transform="translate(12398.754 11425.018) rotate(45)"
            fill="#fff"
          >
            <path
              d="M -16294.4248046875 739.6232299804688 L -16299.7021484375 734.414306640625 L -16299.6337890625 720.2534790039062 L -16295.041015625 724.7864990234375 L -16294.3388671875 725.4803466796875 L -16293.6357421875 724.7860717773438 L -16289.009765625 720.2137451171875 L -16289.017578125 721.7353515625 L -16289.0166015625 721.7351684570312 L -16289.0810546875 734.3488159179688 L -16294.4248046875 739.6232299804688 Z"
              stroke="none"
            />
            <path
              d="M -16294.4248046875 738.2182006835938 L -16290.0791015625 733.9286499023438 L -16290.029296875 724.139404296875 L -16290.029296875 724.1397094726562 L -16290.021484375 722.6201782226562 L -16294.337890625 726.8859252929688 L -16298.6455078125 722.6343383789062 L -16298.7001953125 733.9982299804688 L -16294.4248046875 738.2182006835938 M -16294.4248046875 741.0283203125 L -16300.7041015625 734.830322265625 L -16300.6220703125 717.8727416992188 L -16294.3388671875 724.0748291015625 L -16287.9970703125 717.8072509765625 L -16288.0048828125 719.3309326171875 L -16287.2197265625 718.555908203125 L -16288.0048828125 719.3309326171875 L -16288.0830078125 734.7689208984375 L -16294.4248046875 741.0283203125 Z M -16300.62890625 719.2521362304688 L -16301.333984375 718.555908203125 L -16300.62890625 719.2521362304688 Z"
              stroke="none"
              fill="#3c4fe0"
            />
          </g>
          <path
            id="Path_232"
            data-name="Path 232"
            d="M16635.5-252.5l37.061-34.647"
            transform="translate(-16308 703)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const TimeReduction = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="77.366"
        height="75.888"
        viewBox="0 0 77.366 75.888"
      >
        <g
          id="Group_353"
          data-name="Group 353"
          transform="translate(-1037 -411)"
        >
          <ellipse
            id="Ellipse_82"
            data-name="Ellipse 82"
            cx="33.683"
            cy="33.684"
            rx="33.683"
            ry="33.684"
            transform="translate(1037 419.521)"
            fill="#d3eaff"
          />
          <g
            id="Ellipse_81"
            data-name="Ellipse 81"
            transform="translate(1047 411)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          >
            <ellipse
              cx="33.683"
              cy="33.684"
              rx="33.683"
              ry="33.684"
              stroke="none"
            />
            <ellipse
              cx="33.683"
              cy="33.684"
              rx="32.683"
              ry="32.684"
              fill="none"
            />
          </g>
          <path
            id="Path_235"
            data-name="Path 235"
            d="M17386.916-274.06l3.768-4.055,3.766,4.055-3.766-4.055v27.564l20.139-.151-3.387-3.194,3.387,3.194-3.387,3.291"
            transform="translate(-16311.822 700.615)"
            fill="none"
            stroke="#3c4fe0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const CheckCircle = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="19"
        viewBox="0 0 19 19"
      >
        <g
          id="Group_315"
          data-name="Group 315"
          transform="translate(-430 -3438)"
        >
          <path
            id="Path_231"
            data-name="Path 231"
            d="M16749.98,2748.861l2.313,2.313,4.9-4.9"
            transform="translate(-16313.883 698.56)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
          <circle
            id="Ellipse_72"
            data-name="Ellipse 72"
            cx="8.5"
            cy="8.5"
            r="8.5"
            transform="translate(431 3439)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const CalendarIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="74"
        height="73.255"
        viewBox="0 0 74 73.255"
      >
        <g
          id="Group_330"
          data-name="Group 330"
          transform="translate(-1037 -410)"
        >
          <g
            id="Group_329"
            data-name="Group 329"
            transform="translate(1041 418.603)"
          >
            <rect
              id="Rectangle_184"
              data-name="Rectangle 184"
              width="64"
              height="54.652"
              transform="translate(-4 10)"
              fill="#d3eaff"
            />
            <g
              id="Rectangle_228"
              data-name="Rectangle 228"
              transform="translate(6 0)"
              fill="none"
              stroke="#3c4fe0"
              strokeWidth="2"
            >
              <rect width="64" height="55.652" stroke="none" />
              <rect x="1" y="1" width="62" height="53.652" fill="none" />
            </g>
          </g>
          <path
            id="Path_246"
            data-name="Path 246"
            d="M21351.5-286.627v9.438h39.564v-9.438"
            transform="translate(-20292 696.627)"
            fill="none"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
          <path
            id="Path_247"
            data-name="Path 247"
            d="M21403-258.643h-62.908"
            transform="translate(-20292 697.505)"
            fill="#d3eaff"
            stroke="#3c4fe0"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const CalendarGray = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="23.033"
        viewBox="0 0 21 23.033"
      >
        <g
          id="Group_244"
          data-name="Group 244"
          transform="translate(-1076.328 5823.033)"
        >
          <g
            id="Rectangle_181"
            data-name="Rectangle 181"
            transform="translate(1076.328 -5819)"
            fill="none"
            stroke="#bbb"
            strokeWidth="2"
          >
            <rect width="21" height="19" stroke="none" />
            <rect x="1" y="1" width="19" height="17" fill="none" />
          </g>
          <path
            id="Path_191"
            data-name="Path 191"
            d="M1077.105-5811.87h19.47"
            fill="none"
            stroke="#bbb"
            strokeWidth="2"
          />
          <path
            id="Path_192"
            data-name="Path 192"
            d="M1079.951-5823.033V-5818h13.56v-5.034"
            fill="none"
            stroke="#bbb"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};

export const GrayClock = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g
          id="Group_245"
          data-name="Group 245"
          transform="translate(-1133 5821)"
        >
          <g
            id="Ellipse_57"
            data-name="Ellipse 57"
            transform="translate(1133 -5821)"
            fill="none"
            stroke="#bbb"
            strokeWidth="2"
          >
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11" fill="none" />
          </g>
          <path
            id="Path_193"
            data-name="Path 193"
            d="M1145-5813.4v6.786h4.777"
            transform="translate(0 -1)"
            fill="none"
            stroke="#bbb"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  );
};
