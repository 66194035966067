import * as React from 'react';
import axios from 'axios';

import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Page from './../../../components/Page';
import BlogFull from './../../../components/Blogs/BlogFull';
import PageLoader from '../../../components/PageLoader';

const BlogPage = ({ location }) => {
  const apiBase = process.env.REACT_IMAGE_BASE_URL;
  const url = location.pathname ? location.pathname : '';
  const urlArray = url ? url.split('/') : '';

  const [appState, setAppState] = useState({
    loading: false,
    post: null,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `${apiBase}/blogs/${urlArray[2]}`;

    axios
      .get(apiUrl)
      .then(function (response) {
        // handle success
        console.log(response.data);
        setAppState({ loading: false, post: response.data });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, [setAppState]);

  const post = appState?.post;
  const loading = appState?.loading;

  return (
    <>
      {loading && <PageLoader />}

      <Page className={classNames('blog-full', loading && 'hidden')}>
        {post && <BlogFull data={post} />}
      </Page>
    </>
  );
};

export default BlogPage;
